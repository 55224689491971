var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[(_vm.user_role != 12)?_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'ni ni-shop text-white',
          path: '/mainDashboard',
        }}}):_vm._e(),(_vm.user_role != 12)?_c('sidebar-item',{attrs:{"link":{
          name: 'Projects',
          icon: 'ni ni-building text-green',
        }}},[(_vm.project_module.project_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{ name: 'Projects', path: '/projectListing' }}}):_vm._e(),(_vm.project_module.project_listing == 'YES' && _vm.user_role == 3)?_c('sidebar-item',{attrs:{"link":{ name: 'Opportunities'}}},[(_vm.project_module.project_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{ name: 'Opportunities Open', path: '/opportunityListingOpen' }}}):_vm._e(),(_vm.project_module.project_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{ name: 'Opportunities Won', path: '/opportunityListingWon' }}}):_vm._e(),(_vm.project_module.project_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{ name: 'Opportunities Lost', path: '/opportunityListingLost' }}}):_vm._e(),(_vm.project_module.project_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{ name: 'Opportunities Archived', path: '/opportunityListingArchived' }}}):_vm._e()],1):_vm._e(),(_vm.project_module.project_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{ name: 'Project Site', path: '/projectSiteListingFilter' }}}):_vm._e()],1):_c('sidebar-item',{attrs:{"link":{
          name: 'Project Site',
          icon: 'ni ni-building text-green',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Project Site',
            path: '/projectSiteListingContractor',
          }}})],1),(
          _vm.user_role == 1 ||
          _vm.user_role == 3 ||
          _vm.user_role == 9 ||
          _vm.user_role == 10 ||
          _vm.user_role == 15 ||
          _vm.user_role == 16
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Setup',
          icon: 'ni ni-folder-17 text-red',
        }}},[(_vm.setup_module.project_engineer_group_listing == 'YES' && _vm.user_role != 16)?_c('sidebar-item',{attrs:{"link":{ name: 'Group of Project Engineers', path: '/setups/projectEngineerGroupListing' }}}):_vm._e(),(_vm.setup_module.module_brand_listing == 'YES' && _vm.user_role != 16)?_c('sidebar-item',{attrs:{"link":{ name: 'Module Brand', path: '/setups/moduleBrandListing' }}}):_vm._e(),(_vm.setup_module.developer_listing == 'YES' && _vm.user_role != 16)?_c('sidebar-item',{attrs:{"link":{ name: 'Developer', path: '/setups/developerListing' }}}):_vm._e(),(_vm.setup_module.contractor_listing == 'YES' && _vm.user_role != 16)?_c('sidebar-item',{attrs:{"link":{ name: 'Contractor', path: '/setups/contractorListing' }}}):_vm._e(),(_vm.setup_module.user_listing == 'YES' && _vm.user_role != 16)?_c('sidebar-item',{attrs:{"link":{ name: 'User', path: '/setups/UserListing' }}}):_vm._e(),(_vm.setup_module.project_type_listing == 'YES' && _vm.user_role != 16)?_c('sidebar-item',{attrs:{"link":{ name: 'Project Type', path: '/setups/projectTypeListing' }}}):_vm._e(),(_vm.setup_module.project_category_listing == 'YES' && _vm.user_role != 16)?_c('sidebar-item',{attrs:{"link":{
            name: 'Project Category',
            path: '/setups/projectCategoryListing',
          }}}):_vm._e(),(_vm.setup_module.safety_observation_category_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{
            name: 'Safety Observation Category',
            path: '/setups/safetyObservationCategoryListing',
          }}}):_vm._e(),(_vm.setup_module.safety_observation_category_listing == 'YES' && _vm.user_role != 16)?_c('sidebar-item',{attrs:{"link":{
            name: 'Safety Observation Priority',
            path: '/setups/safetyObservationPriorityListing',
          }}}):_vm._e(),(_vm.setup_module.doc_repo_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{
            name: 'Document Tracking Templates',
            path: '/setups/DocRepo/Template/Listing',
          }}}):_vm._e(),(_vm.setup_module.eform_template_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{
            name: 'Eform Templates',
            path: '/setups/Eform/Template/Listing',
          }}}):_vm._e(),(_vm.setup_module.eform_template_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{
            name: 'Eform Approvers',
            path: '/setups/Eform/Approver/Detail',
          }}}):_vm._e()],1):_vm._e(),(
          _vm.user_role == 1 ||
          _vm.user_role == 3
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Audit Logs',
          icon: 'ni ni-vector text-red',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Audit Log Document', path: '/audits/auditLogDocumentListing' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Audit Log Project', path: '/audits/auditLogProjectListing' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Audit Log Setup', path: '/audits/auditLogSetupListing' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Audit Log Task', path: '/audits/auditLogTaskListing' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Audit Log Template', path: '/audits/auditLogTemplateListing' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Audit Log Safety Inspection', path: '/audits/auditLogSafetyInspectionListing' }}})],1):_vm._e(),(
          _vm.user_role == 1 ||
          _vm.user_role == 3 ||
          _vm.user_role == 9 ||
          _vm.user_role == 10 ||
          _vm.user_role == 15 ||
          _vm.user_role == 8 ||
          _vm.user_role == 19
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Template',
          icon: 'ni ni-collection text-blue',
        }}},[(_vm.template_module.milestone_template_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{
            name: 'Milestone Template',
            path: '/templates/milestoneTemplateListing',
          }}}):_vm._e(),(_vm.template_module.task_template_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{
            name: 'Site Task Template',
            path: '/templates/taskTemplateListing',
          }}}):_vm._e(),(false)?_c('sidebar-item',{attrs:{"link":{
            name: 'Site Document Template',
            path: '/templates/documentTemplateListing',
          }}}):_vm._e(),(_vm.template_module.project_task_template_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{
            name: 'Project Task Template',
            path: '/templates/ProjectTaskTemplateListing',
          }}}):_vm._e(),(false)?_c('sidebar-item',{attrs:{"link":{
            name: 'Project Document Template',
            path: '/templates/projectDocumentTemplateListing',
          }}}):_vm._e()],1):_vm._e(),(
          _vm.user_role != 12
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'PRM',
          icon: 'ni ni-cloud-upload-96 text-blue',
        }}},[(_vm.project_module.project_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{
            name: 'Import PRM Records',
            path: '/prm/importPRMRecords',
          }}}):_vm._e(),(_vm.project_module.project_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{
            name: 'Create Projects',
            path: '/prm/createProjectsFromPrm',
          }}}):_vm._e()],1):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Health & Safety',
          icon: 'ni ni-settings text-blue',
        }}},[(_vm.health_safety_module.safety_observation_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{
            name: 'Safety Inspection',
            path: '/healthSafety/safetyObservationListing',
          }}}):_vm._e(),(_vm.health_safety_module.safety_observation_report_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{
            name: 'Safety Inspection Report',
            path: '/healthSafety/safetyObservationReport',
          }}}):_vm._e(),(_vm.health_safety_module.safety_observation_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{
            name: 'My Open Safety Observations',
            path: '/healthSafety/safetyObservationItemOpenPendingListing',
          }}}):_vm._e(),(_vm.health_safety_module.toolbox_meeting_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{
            name: 'Toolbox Meeting',
            path: '/healthSafety/toolboxMeetingListing',
          }}}):_vm._e()],1),_c('sidebar-item',{attrs:{"link":{
          name: 'EForm',
          icon: 'ni ni-archive-2',
        }}},[(_vm.eform_module.eform_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{
            name: 'Eforms',
            path: '/eForms/EformListing',
          }}}):_vm._e(),(_vm.eform_module.eform_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{
            name: 'Evaluation Forms',
            path: '/eForms/ReviewerListing/evaluation',
          }}}):_vm._e(),(_vm.eform_module.eform_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{
            name: 'Daily Pre-Act Approval Forms',
            path: '/eForms/ReviewerListing/approval',
          }}}):_vm._e(),(_vm.eform_module.eform_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{
            name: 'Assessment Forms',
            path: '/eForms/ReviewerListing/assessment',
          }}}):_vm._e()],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Eform Submission',
          icon: 'ni ni-archive-2',
        }}},[(_vm.eform_module.eform_submitter_listing == 'YES' && _vm.user_role==12)?_c('sidebar-item',{attrs:{"link":{
              name: 'EPC Evaluation Form',
              path: '/eForms/SubmitterListing/evaluation'
          }}}):_vm._e(),(_vm.eform_module.eform_submitter_listing == 'YES' && _vm.user_role==12)?_c('sidebar-item',{attrs:{"link":{
              name: 'EPC Daily Pre-Act Approval Form',
              path: '/eForms/SubmitterListing/approval'
          }}}):_vm._e(),(_vm.eform_module.eform_submitter_listing == 'YES')?_c('sidebar-item',{attrs:{"link":{
            name: 'Assessment Form',
            path: '/eForms/SubmitterListing/assessment'
        }}}):_vm._e()],1)],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }