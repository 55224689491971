
<template>
  <div class="wrapper">
    <div class="main-content">
      <map-dashboard-navbar :type="$route.meta.navbarType"></map-dashboard-navbar>

      <div>
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <router-view></router-view>
        </fade-transition>
      </div>
      <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import MapDashboardNavbar from "./MapDashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import { FadeTransition } from "vue2-transitions";
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
import loginFunctionMix from "@/assets/js/loginFunctionMix.js";
import SidebarItem from "../../components/SidebarPlugin/SidebarItem.vue";

export default {
  mixins: [ErrorFunctionMixin, loginFunctionMix],
  components: {
    MapDashboardNavbar,
    ContentFooter,
    DashboardContent,
    FadeTransition,
    SidebarItem,
  },
  created() {
    //var project_category_access = JSON.parse(window.localStorage.getItem('project_category_access'))
    //this.setup_module.project_category_listing = project_category_access.access_listing
    this.getLoginUserRoleAccessMenu();
    var userInfo = JSON.parse(window.localStorage.getItem("loginUser"));
    this.user_role = userInfo.role_id;

    // this.getProjectType();
  },
  data() {
    return {
      user_role: null,
      setup_modules: [
        {
          developer_module: null,
          developer_listing: 0,
        },
      ],
      project_types: [],
    };
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },

    // getProjectType() {
    //   axios
    //     .get(apiDomain + "setup/project_type", { headers: getHeader() })
    //     .then((response) => {
    //       if (response.status === 200) {
    //         this.project_types = response.data.data;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error.response.status);
    //       this.errorFunction(error, "Project Type");
    //     });
    // },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss">
</style>
