import {apiDomain, getHeader} from '@/assets/js/config.js'
import axios from 'axios'

/*
 * Module codes.
 * Copied from [api-semcorp] - app\Http\Controllers\SemCorp\UserAccess\RoleAccessController.php - userRoleForEachModule()
 * Sync these module codes whenever adding/removing modules.
 */
const SETUP_MODULES = [
  'DEVELOPER',
  'CONTRACTOR',
  'USER',
  'PROJECT_TYPE',
  'PROJECT_CATEGORY',
  'MODULE_BRAND',
  'PROJECT_ENGINEER_GROUP',
  'SAFETY_OBSERVATION_CATEGORY',
  'DOC_REPO',
  'EFORM_TEMPLATE',
];

const TEMPLATE_MODULES = [
  'MILESTONE_TEMPLATE', 'MILESTONE_TEMPLATE_DETAIL',
  'TASK_TEMPLATE', 'TASK_TEMPLATE_DETAIL',
  'DOCUMENT_TEMPLATE', 'DOCUMENT_TEMPLATE_DETAIL',
  'PROJECT_TASK_TEMPLATE', 'PROJECT_TASK_TEMPLATE_DETAIL',
  'PROJECT_DOCUMENT_TEMPLATE', 'PROJECT_DOCUMENT_TEMPLATE_DETAIL',
];

const PROJECT_MODULES = [
  'PROJECT',
  'PROJECT_MILESTONE',
  'PROJECT_GROUP',
  'PROJECT_SITE',
  'SITE_CONTRACTOR',
];

const SITE_TRANSACTION_MODULES = [
  'TASK',
  'SITE_DOCUMENT',
  'SITE_DOCUMENT_UPLOADS',
  'SITE_DOCUMENT_RECURRING',
  'SITE_DOCUMENT_APPROVAL',
  'DOC_REPO_UPLOAD',
  'DOC_REPO_REVIEW',
];

const PROJECT_TRANSACTION_MODULES = [
  'PROJECT_TASK',
  'PROJECT_DOCUMENT',
  'PROJECT_DOCUMENT_UPLOADS',
  'PROJECT_DOCUMENT_RECURRING',
  'PROJECT_DOCUMENT_APPROVAL',
];

const HEALTH_SAFETY_MODULES = [
  'SAFETY_OBSERVATION',
  'SAFETY_OBSERVATION_REPORT',
  'TOOLBOX_MEETING',
];

const EFORM_MODULES = [
  'EFORM',
  'EFORM_SUBMITTER'
];

export default {
  data() {
    return {
      setup_module:{
        contractor_listing: null,
        developer_listing: null,
        doc_repo_listing: null,
        module_brand_listing: null,
        project_category_listing: null,
        project_engineer_group_listing: null,
        project_type_listing: null,
        safety_observation_category_listing: null,
        user_listing: null,
        eform_template_listing:null,
      },
      project_module:{
        project_listing: null,
        project_milestone: null,
        project_group_listing: null,
        project_site_listing: null,
      },
      template_module:{
        document_template_listing: null,
        document_template_detail_Listing: null,
        milestone_template_listing: null,
        milestone_template_detail_listing: null,
        project_document_template_listing: null,
        project_document_template_detail_Listing: null,
        project_task_template_listing: null,
        project_task_template_detail_listing: null,
        task_template_listing: null,
        task_template_detail: null,
      },
      site_transaction_module:{
        site_document_listing: null,
        site_task_listing: null,
      },
      health_safety_module:{
        safety_observation_listing: null,
        safety_observation_report_listing: null,
        toolbox_meeting_listing: null,
      },
      eform_module:{
        eform_listing : null,
        eform_submitter_listing : null,
      }
    }
  },

  methods: {
    getLoginUserRoleAccessMenu()
    {
      axios.get(apiDomain + 'user_access/role_access.for_each_module', { headers: getHeader() })
      .then(response => {
        this.setAllListingAccesses(response.data.data)
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "User Role Access Information")
      } );
    },

    setAllListingAccesses(data) {
      // Setup modules
      for (const code of SETUP_MODULES) {
        this.setListingAccess(data.setup_modules, code, this.setup_module)
      }

      // Project modules
      for (const code of PROJECT_MODULES) {
        this.setListingAccess(data.project_modules, code, this.project_module)
      }

      // Template modules
      for (const code of TEMPLATE_MODULES) {
        this.setListingAccess(data.template_modules, code, this.template_module)
      }

      // Site transaction modules
      this.setListingAccess(data.site_transaction_modules, 'SITE_DOCUMENT', this.site_transaction_module)
      this.setListingAccess(data.site_transaction_modules, 'TASK', this.site_transaction_module, 'site_task_listing')

      // Health & safety modules
      for (const code of HEALTH_SAFETY_MODULES) {
        this.setListingAccess(data.health_safety_modules, code, this.health_safety_module)
      }
      
      for (const code of EFORM_MODULES){
        this.setListingAccess(data.eform_modules ,code ,this.eform_module)
      }
    },

    /**
     * Set listing access for navigation menu.
     *
     * @param {array}  modules   Array of modules.
     * @param {string} code      Module code to look up in the array of modules.
     * @param {object} accessObj Access object to set.
     * @param {string} key       Key to set in the access object.
     *                           If omitted, create it from the module code.
     */
    setListingAccess(modules, code, accessObj, key) {
      for (const item of modules) {
        if (item.module_code == code) {
          // If key is not provided...
          if (!key) {
            // Key is the lower case code followed by '_listing'
            key = code.toLowerCase() + '_listing'
          }

          accessObj[key] = item.access_listing
          break;
        }
      }
    },

    getLoginUserRoleAccess()
    {
      axios.get(apiDomain + 'user_access/role_access.for_each_module', { headers: getHeader() })
      .then(response => {
        this.storeAllModuleAccesses(response.data.data)
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "User Role Access Information")
      } );
    },

    storeAllModuleAccesses(data) {
      // Setup modules
      for (const code of SETUP_MODULES) {
        this.storeModuleAccess(data.setup_modules, code)
      }

      // Project modules
      for (const code of PROJECT_MODULES) {
        this.storeModuleAccess(data.project_modules, code)
      }

      // Template modules
      for (const code of TEMPLATE_MODULES) {
        let key = undefined

        // Some modules uses non standard key.
        if (code == 'DOCUMENT_TEMPLATE')
          key = 'site_document_template_access'
        else if (code == 'DOCUMENT_TEMPLATE_DETAIL')
          key = 'site_document_template_detail_access'
        else if (code == 'TASK_TEMPLATE')
          key = 'site_task_template_access'
        else if (code == 'TASK_TEMPLATE_DETAIL')
          key = 'site_task_template_detail_access'

        this.storeModuleAccess(data.template_modules, code, key)
      }

      // Site transaction modules
      for (const code of SITE_TRANSACTION_MODULES) {
        let key = undefined

        // Some modules uses non standard key.
        if (code == 'SITE_DOCUMENT_UPLOADS')
          key = 'site_document_upload_access'
        else if (code == 'TASK')
          key = 'site_task_access'

        this.storeModuleAccess(data.site_transaction_modules, code, key)
      }

      // Project transaction modules
      for (const code of PROJECT_TRANSACTION_MODULES) {
        let key = undefined

        // Some modules uses non standard key.
        if (code == 'PROJECT_DOCUMENT_UPLOADS')
          key = 'project_document_upload_access'

        this.storeModuleAccess(data.project_transaction_modules, code, key)
      }

      // Health & safety modules
      for (const code of HEALTH_SAFETY_MODULES) {
        this.storeModuleAccess(data.health_safety_modules, code)
      }

        for (const code of EFORM_MODULES) {
            this.storeModuleAccess(data.eform_modules, code)
        }
    },

    /**
     * Store access flags into localStorage for the specified module.
     *
     * @param {array}  modules Array of modules.
     * @param {string} code    Module code to look up in the array of modules.
     * @param {string} key     Key to use in localStorage.
     *                         If omitted, create it from the module code.
     */
    storeModuleAccess(modules, code, key) {
      for (const item of modules) {
        if (item.module_code == code) {
          const access = {
            module_code: item.module_code,
            access_listing: item.access_listing,
            access_create: item.access_create,
            access_show: item.access_show,
            access_edit: item.access_edit,
            access_delete: item.access_delete,
          }

          // If key is not provided...
          if (!key) {
            // Key is the lower case code followed by '_access'
            key = code.toLowerCase() + '_access'
          }

          localStorage.setItem(key, JSON.stringify(access))
          break;
        }
      }
    },
  },
}
