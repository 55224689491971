import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth)
  {
      var authUser = JSON.parse(window.localStorage.getItem('authUser'))
      if ( authUser && authUser.access_token )
      {
          next()
      }
      else
      {
          window.localStorage.setItem('my_current_url', to.fullPath)
          next({name: 'Login'})       
      }
  }
  next()
})

export default router;
