<template>
  <div>

    <div class="main-content">
      <!-- <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar> -->
      <b-navbar toggleable="lg" type="dark"
        style="position: fixed; width: 100%; padding-bottom: 10px; z-index: 999; top: 0; background-color: #09333A;">
        <b-navbar-brand to="/dashboard" target="_blank" style="margin-left: 1%;">
          <img src="/img/theme/sembcorp-logo-white.png" alt="">
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <!-- <b-link></b-link> -->
            <!-- <b-link to="administrator" target="_blank" v-if="user_info[0].role == 'admin'" size="sm"
              class=" btn btn-outline-success rounded-pill" style="background-color: #71cfb0;">
              <span class="btn-inner--icon mr-1"><i class="ni ni-circle-08"></i></span>
              <span class="btn-inner--text "><strong>Administrator </strong></span>
            </b-link> -->
            <base-dropdown class="nav-item" tag="li" title-classes="nav-link" title-tag="a" icon="ni ni-bell-55"
              menu-classes="dropdown-menu-xl dropdown-menu-right py-0 ">

            </base-dropdown>
            <b-nav-item-dropdown icon right>
              <!-- Using 'button-content' slot -->
              <template #button-content>
                <span
                  style="margin-right: 5px; background-color: #71cfb0; color: #007C5A; padding-left: 3px; padding-right: 3px; padding-top: 1px; padding-bottom: 1px; border-radius: 4px;"><i
                    class="far fa-user"></i></span>
                <b>{{ loginUserData.email }}</b>
              </template>
              <b-dropdown-item href="#">Profile</b-dropdown-item>
              <b-dropdown-item to="/login">Sign Out</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </div>
</template>

<script src="{{ asset('js/app.js?version='.date('YmdHis')) }}"></script>
<script>
import { CollapseTransition } from 'vue2-transitions';
import { BaseNav, Modal } from '@/components';
import axios from 'axios'
import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'

export default {
  components: {
    CollapseTransition,
    BaseNav,
    Modal
  },
  props: {
    type: {
      type: String,
      default: 'default', // default|light
      description: 'Look of the dashboard navbar. Default (Green) or light (gray)'
    }
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    }
  },
  data() {
    return {
      model:
      {
        project:
        {
          project_id: null,
          project_code: null,
        },
        group:
        {
          group_id: null,
          group_code: null,
        },
        site:
        {
          site_id: null,
          site_code: null,
        },
      },
      defaultDashboard: [],
      projects: [],
      groups: [],
      sites: [],
      modals: {
        formDashboard: false,
      },
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      loginUserData : JSON.parse(window.localStorage.getItem('loginUser'))
    };
  },
  methods: {
    redirectToCniProjectListing() {
      // this.$router.push({path: 'projectListingCNI' })
      // 20240102 redirect to project listing instead of cni listing
      this.$router.push({path: 'projectListing' })
    },
    notifyVueformDashboard()
    {
      if ( this.model.project.project_id == 'x' || this.model.project.project_id == ''
      || this.model.group.group_id == 'x' || this.model.group.group_id == ''
      || this.model.site.site_id == 'x' || this.model.site.site_id == '')
      {
        this.$notify({
        message:
            '<b>Invalid Input : </b> - Your must select at least ONE Project & ONE Group & ONE Site.',
        timeout: 10000,
        icon: 'ni ni-bulb-61',
        type: 'danger',
        });
      }
      else
      {
        this.$notify({
        message:
          '<b>Default Dashboard : </b> - New Default Dashbord has been set and Save to your User Profile.',
        timeout: 10000,
        icon: 'ni ni-bulb-61',
        type: 'default',
        });
      }
      this.modals.formDashboard = false
    },
    clearModal()
    {
        this.modals.formDashboard = false
    },
    notready() {
          this.$notify({
          message:
            '<b>Under Developer : </b> - New Task, Site & Populate / Import From Template function is not ready.',
          timeout: 10000,
          icon: 'ni ni-bulb-61',
          type: 'danger',
          });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    logoutFunction()
    {
      var loginUserData = JSON.parse(window.localStorage.getItem('loginUser'))
      console.log(loginUserData)
      if (loginUserData)
      {
        localStorage.clear()
      }
      this.$router.push({name: 'Login'})
    },
    goPage(page)
    {
        this.$router.push({path: page })
    },
  }
};
</script>

<style>
.navbar-vertical.navbar-expand-xs .navbar-nav>.nav-item>.nav-link.active {
  background: #033639;
  background-color: #033639;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: white;
}

/* style for top right dropdown menu (user email) */
.dropdown-menu {
  background-color: #fff !important;
}

.dropdown-item:hover {
  background-color: #27c298;
}

.dropdown-menu .dropdown-item {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
}

.dropdown-item.active, .dropdown-item:active {
    color: #1b3539;
    text-decoration: none;
    background-color: transparent;
}

.dropdown-item:hover, .dropdown-item:focus {
    color: #1b3539;
    text-decoration: none;
    background-color: #f6f9fc;
}
</style>