export default {
  computed: {

  },
  data() {
    return {

    }
  },
  methods: {
    closeWindow()
    {
      close()
    },
    redErrorNotify(error_message)
    {
      this.$notify({
      message: error_message,
      timeout: 10000,
      icon: 'ni ni-bulb-61',
      type: 'danger',
      });
    },
    errorFunction(error, module)
    {
      if ( error.response.status == 401)
      {
        this.$router.push('/login')
      }
      else if ( error.response.status == 403)
      {
        this.$notify({
          message:
            '<b>Insufficient Permissions to : ' + module + '</b> - You dont have Access to this Module or Function in the Module. Please Contact Administrator.',
          timeout: 15000,
          icon: 'ni ni-bulb-61',
          type: 'warning',
        });
        this.$router.back()
      }
      else if ( error.response.status == 500 )
      {
        this.$notify({
          message:
            // '<b>System Warning : </b> - The System is Currently Busy or Un-Available.',
            '<b>System Warning : </b> - An error has occured. Please contact Sembcorp staff you know or email to sembsolar.momens@sembcorp.com',
          timeout: 15000,
          icon: 'ni ni-bulb-61',
          type: 'warning',
        });
      }
      else if ( error.response.status == 404 || error.response.status == 405 || error.response.status == 409 )
      {
        this.$notify({
          message:
            '<b>Function Warning : </b> - The Function is Un-Available or Invalid.',
          timeout: 15000,
          icon: 'ni ni-bulb-61',
          type: 'warning',
        });
      }
      else
      {
        let errorList = '<br>'
        let noError = 0
        Object.keys(error.response.data.errors).forEach(function(key)
        {
          noError = noError + 1
          errorList = errorList + 'No.' + noError + ' : ' +(error.response.data.errors[key])
          errorList = errorList + '<br>'
        });

        this.$notify({
          message:
            '<b>Input Validation Warning :  </b> - ' + errorList,
          timeout: 10000,
          icon: 'ni ni-bulb-61',
          type: 'warning',
        });
      }
    },
  },

}