export const apiDomain  = process.env.VUE_APP_API_DOMAIN
export const LoginURL   = apiDomain + 'oauth/token'
export const homeLink   = process.env.VUE_APP_HOME_LINK
export const fileServer = process.env.VUE_APP_FILE_SERVER

export const clientId     = process.env.VUE_APP_CLIENT_ID
export const clientSecret = process.env.VUE_APP_CLIENT_SECRET

// --------------------------------------------------

export const getHeader = function () {
    const tokenData = JSON.parse(window.localStorage.getItem('authUser'))
    const headers = {
        'Accept' : 'application/json',
        'Authorization' : 'Bearer ' + tokenData.access_token
    }

    return headers
}

export const getcurrentDate = function ()
{
  const current       = new Date()
  const currentMonth  = String((current.getMonth()+1)).padStart(2, '0')
  const currentDay    = String(current.getDate()).padStart(2, '0')
  const currentDate   = current.getFullYear()+'-'+currentMonth+'-'+currentDay

  return currentDate
}

export const getUserInfo = function () {
    const loginUserData = JSON.parse(window.localStorage.getItem('loginUser'))
    return loginUserData
}

export const parseDateToUTC = function(dateString) {
    // Parse the date string
    const date = new Date(dateString);

    // Get the individual components of the date
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Convert the parsed date to UTC
    const utcDate = new Date(Date.UTC(year, month, day, hours, minutes, seconds));

    return utcDate;
}
